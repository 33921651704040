import { Fragment } from "react";
import LoaderComponent from './LoaderComponent';
import { AppBar, Box, Container, Grid, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import MainComponent from './MainComponent';
import ErrorComponent from './ErrorComponent';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {ReactComponent as StatosferaLogo} from '../assets/logo_statosfera_footer.svg';
import TermsComponent from "./TermsComponent";

export default() => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const pageTitle = theme.branding.pageTitle ? theme.branding.pageTitle : t('main_title');
    const logoImage = theme.branding.logoUrl ? theme.branding.logoUrl : 'https://static.statosfera.com/image/statosfera-logo';
    const displayImg = (theme.branding.logoUrl) ? 'block' : 'block';

    const isOnMobile = window.innerWidth < 900;
    const barPosition = isOnMobile ? 'relative' : 'fixed';
    const topMargin = isOnMobile ? '0px' : '94px';

    return(
        <Fragment>
            <AppBar position="static" color="" sx={{position: barPosition, minHeight: '94px', zIndex: '1000'}}>
                <Toolbar>
                    <Stack direction="row" sx={{width: '100%', height: '94px', maxWidth: '1128px !important', margin: 'auto', pl: {xs: 0, lg: 3}, pr: {xs: 3, lg: 3}}}>
                        <Box component="img" src={logoImage} alt={"Logo"} sx={{margin:'auto', height: "46px", width: "110px", display: displayImg, objectFit: 'scale-down'}} />
                        <Typography variant="h6" color="black" noWrap sx={{flex: 1, margin: 'auto', marginLeft: {xs: '16px', lg: '20px'}, fontSize: {xs: '16px', lg: '20px'}}}>{pageTitle}</Typography>
                    </Stack>
                </Toolbar>
            </AppBar>
            <main>
                <Box sx={{width: '100%', mt: 0, pb: 8, pt: topMargin}}>
                    <Box component="main">
                    <Container sx={{width: '100%', margin: 'auto', maxWidth: '1128px !important', position: 'relative', minHeight: 'calc(100vh - 160px)', display: 'flex', flexDirection: 'column' }}>
                        <Routes>
                            <Route path='/' element={<MainComponent />} />
                            <Route path='/hu' element={<MainComponent />} />
                            <Route path='/en' element={<MainComponent />} />
                            <Route path="/error" element={<ErrorComponent />} />
                        </Routes>
                    </Container>
                    </Box>
                </Box>
            </main>
            <LoaderComponent />
        <footer>
            <Box className="mainFooter" sx={{position: barPosition}}>
                <Grid container direction={{ xs: 'column', md: 'row' }} spacing={0} style={{margin: 'auto', maxWidth: '600px', marginTop: '16px', marginBottom: '16px'}} justifyContent="space-between" alignItems="center">
                    <Grid item md={0} sx={{mt: {xs: 1, md: 0}}}>
                        <StatosferaLogo />
                    </Grid>
                    <Grid item md={0} sx={{mt: {xs: 1, md: 0}}}>
                        <Typography className="regular12" variant="body">{t('all_rights')}</Typography>
                    </Grid>
                    <Grid item md={0} sx={{mt: {xs: 1, md: 0, display: 'none'}}}>
                        <a className="regular12" href="" target="new">{t('terms_of_service')}</a>
                    </Grid>
                    <Grid item sx={{mt: {xs: 1, md: 0, display: 'none'}}}>
                        <a className="regular12" href="" target="new">{t('impressum')}</a>
                    </Grid>
                    <Grid item sx={{mt: {xs: 1, md: 0, display: 'none'}}}>
                        <a className="regular12" href="" target="new">{t('cookie_policy')}</a>
                    </Grid>
                </Grid>
            </Box>
        </footer>
        </Fragment>
    );
}