import { Box, Stack, useTheme } from "@mui/material";
import {ReactComponent as ErrorLogo} from '../assets/img_state_syncing_failed.svg';
import { ColorUtils } from "../util/ColorUtils";
import { useTranslation } from "react-i18next";

export default() => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    return (
        <Stack sx={{mt: 2}}>
            <h5 style={{margin: 'auto', marginTop: '16px', color: '#1D0F32'}}>{t('hello')}</h5>
            <ErrorLogo style={{margin: 'auto', marginTop: '16px'}} />
            <h4 style={{margin: 'auto', marginTop: '16px', color: ColorUtils.getPrimaryBrandColor(theme)}}>{t('bank_error_title')}</h4>
            <Box className="regular18" sx={{textAlign: 'center', m: 'auto', mt: 2, mb: 2, maxWidth: '800px', textAlign: 'center'}}>{t('bank_error')}</Box>
        </Stack>
    );
}