import { ThemeContext } from "@emotion/react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getConfigRequest } from "../reducer/CommonReducer";
import { QueryUtils } from "../util/QueryUtils";

export default({theme, apiTheme, setApiTheme, children}) => {
    const dispatcher = useDispatch();
    const location = useLocation();
    const clientToken = QueryUtils.getClientFromPath(location?.search);
    if(clientToken && !apiTheme) {
        dispatcher(getConfigRequest(clientToken)).unwrap().then(res => {
            setApiTheme(res.data);
        }, e => {
            console.log("ERROR", e);
        });
    }
    return <ThemeContext.Provider value={theme} apiTheme={apiTheme}>
        {children}
    </ThemeContext.Provider>
}