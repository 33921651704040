import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Card, CardActionArea, CardContent, Divider, Stack } from '@mui/material';
import { UrlUtils } from '../../../util/UrlUtils';
import { ColorUtils } from '../../../util/ColorUtils';
import {ReactComponent as ArrowRight} from '../../../assets/arrow_right_line.svg';
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default({banks, onBankSelected, onReject}) => {
    const {t, i18n} = useTranslation();
    const bankList = banks?.data?.banks;
    const theme = useTheme();
    const primaryColor = ColorUtils.getPrimaryBrandColor(theme);
    const secondaryColor = ColorUtils.getSecondaryBrandColor(theme);
    const selectBank = (item) => {
        if(item?.status == 'ACTIVE') {
            onBankSelected?.(item.id);
        }
    }
    const rejectEnabled = theme?.branding?.rejectEnabled;
    const selectReject = (reason) => {
        onReject?.(reason);
    }
    return(
        <Box sx={{ width: '100%', mt: 0 }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                {bankList?.map(item => {
                    const image = UrlUtils.getImageUrl(item.logo?.url);
                    const rootClass = (item.status != 'ACTIVE') ? 'bankGrid bankDisabled' : 'bankGrid';
                    return(
                        <Grid className={rootClass} key={'bank_item_'+item.id} item xs={1}>
                            <Card sx={{display: 'flex', flexDirection: 'column'}} elevation={0}>
                                <CardActionArea onClick={() => selectBank(item)} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch'}}>
                                    <CardContent sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                        <Stack direction={"row"} justifyContent="flex-end" alignItems="center">
                                            <Box className="logoHolder">
                                                <img src={image} />
                                            </Box>
                                            <div className="medium18 bankName" style={{color: primaryColor}}>{item.name}</div>
                                            <ArrowRight className="arrowRight"/>
                                            <div className="disabledText">{t('not_available')}</div>
                                        </Stack>
                                    </CardContent>
                                    <Divider />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
                {rejectEnabled && 
                <Grid className={"bankGrid"} key={'bank_item_notinlist'} item xs={1}>
                    <Card sx={{display: 'flex', flexDirection: 'column'}} elevation={0}>
                        <CardActionArea onClick={() => selectReject('BANK_NOT_AVAILABLE')} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch'}}>
                            <CardContent sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                <Stack direction={"row"} justifyContent="flex-end" alignItems="center">
                                    <div className="medium14 bankName" style={{color: '#7A869A'}}>{t('bank_not_on_the_list')}</div>
                                    <ArrowRight className="arrowRight"/>
                                </Stack>
                            </CardContent>
                            <Divider />
                        </CardActionArea>
                    </Card>
                </Grid>
                }
                {rejectEnabled && 
                <Grid className={"bankGrid"} key={'bank_item_reject'} item xs={1}>
                    <Card sx={{display: 'flex', flexDirection: 'column'}} elevation={0}>
                        <CardActionArea onClick={() => selectReject('CONSENT_REFUSED')} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch'}}>
                            <CardContent sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                <Stack direction={"row"} justifyContent="flex-end" alignItems="center">
                                    <div className="medium14 bankName" style={{color: '#7A869A'}}>{t('refuse_consent')}</div>
                                    <ArrowRight className="arrowRight"/>
                                </Stack>
                            </CardContent>
                            <Divider />
                        </CardActionArea>
                    </Card>
                </Grid>
                }
            </Grid>
        </Box>
    );
}