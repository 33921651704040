import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export default() => {
    const loading = useSelector(state => state.commonReducer.loading);
    return(
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}