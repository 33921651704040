class ColorUtilsC {
    getPrimaryBrandColor(theme) {
        if(theme?.branding?.primaryBrandColor) {
            return theme.branding.primaryBrandColor;
        }
        return theme.palette.primary.main;
    }
    getSecondaryBrandColor(theme) {
        if(theme?.branding?.secondaryBrandColor) {
            return theme.branding.secondaryBrandColor;
        }
        return theme.palette.secondary.main;
    }

    toRGBA(cssColor) {
        let el = document.createElement("div");
        el.style.color = cssColor;
        el.style.display = "none";
        document.body.appendChild(el);
        let rgba = window.getComputedStyle(el).getPropertyValue("color");
        el.remove();
        let [r, g, b, a] = rgba.match(/[0-9.]+/g).map(n => Number(n));
        if(a === undefined) a = 1; // <-- getPropertyValue returns rgb(...) if there is no transparency, so we add alpha if missing
        return [r, g, b, a];
    }
    toRgbaWithOpacity(cssColor, opacity) {
        const primaryColorToRgba = this.toRGBA(cssColor);
        return 'rgba('+primaryColorToRgba[0]+', '+primaryColorToRgba[1]+', '+primaryColorToRgba[2]+', '+opacity+')';
    }
}

const ColorUtils = new ColorUtilsC();

export {
    ColorUtils 
};