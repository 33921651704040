import { Box, Grid } from "@mui/material";
import {ReactComponent as LogoStatosfera} from '../../../assets/logo_statosfera.svg';
import {ReactComponent as EuAuthorityLogo} from '../../../assets/eu_authority.svg';
import LogoMnbPng from '../../../assets/logo_mnb.png';
import { useTranslation } from "react-i18next";

export default() => {
    const {t, i18n} = useTranslation();
    return(
        <Grid container spacing={0} direction={{ xs: 'row', md: 'row' }} sx={{mt: 2}} rowSpacing={2}>
            <Grid item xs={6} md={4}>
                <Box>
                    <EuAuthorityLogo id="euAuthLogo" style={{position: 'relative', left: '50%', transform: 'translate(-50%, 0)'}} />
                    <a className="regular14" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 4, display: 'block'}} href="https://euclid.eba.europa.eu/register/pir/search" target="_blank">{t('european_banking_authority')}</a>
                </Box>
            </Grid>
            <Grid item xs={6} md={4}>
                <Box>
                    <img src={LogoMnbPng} style={{position: 'relative', left: '50%', transform: 'translate(-50%, 0)', width: '70px'}} />
                    <a className="regular14" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 4, display: 'block'}} href="https://intezmenykereso.mnb.hu/Details/Index?LId=228562&EntityType=Institute&expandAccordions=IntezmenyAlapadatok" target="_blank">{t('aisp_licence')}</a>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box>
                    <LogoStatosfera id="statLogo" style={{width: '100%', position: 'relative', left: '50%', transform: 'translate(-50%, 0)'}} />
                    <a className="regular14" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 4, width: '100px', display: 'block'}} href="https://statosfera.com/" target="_blank">{t('powered_by_statosfera')}</a>
                </Box>
            </Grid>
        </Grid>
    );
}