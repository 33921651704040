import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';

const WhiteButton = styled(Button)(({ theme }) => ({
    color: '#1A1A1A',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Switzer',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '0.2px',
    '&:hover': {
      backgroundColor: '#F1F1F1',
      boxShadow: 'none',
    },
  }));

export default WhiteButton;