import axios from 'axios';
import { config as apiConfig, selectedProfile } from '../config/config';

const axiosInstancePublicApi = axios.create({
    baseURL: apiConfig[selectedProfile].PUBLIC_API_BASE_URL
});

const axiosInstanceAuthApi = axios.create({
    baseURL: apiConfig[selectedProfile].AUTH_API_BASE_URL
});

function getBasicHeaders(addClientId = false, token = null, ipAddress = null) {
    const headers = {
        "Content-Type": "application/json"
    };
    if(addClientId) {
        headers["X-Request-Client-ID"] = "test-client-id";
    }
    if(token) {
        headers["Authorization"] = "Bearer "+token;
    }
    if(ipAddress) {
        headers["X-Real-IP"] = ipAddress;
    }
    return headers;
}

function getBasicOptions(addClientId = false, token = null, ipAddress = null) {
    return {
        headers: getBasicHeaders(addClientId, token, ipAddress),
        validateStatus: false
    }
}

//API requests
async function getConfigData(token) {
    return await axiosInstancePublicApi.get("/mitigate/public/config", getBasicOptions(false, token));
}

async function getClientData(token) {
    return await axiosInstancePublicApi.get("/mitigate/public/client", getBasicOptions(false, token));
}

async function validateClientData({token, bankId, accountNumber}) {
    const data = {bankId, accountNumber};
    return await axiosInstancePublicApi.post("/mitigate/public/validate", JSON.stringify(data), getBasicOptions(false, token));
}

async function getBanks(token) {
    return await axiosInstancePublicApi.get("/mitigate/public/bank", getBasicOptions(false, token));
}

async function generateAuthorization({token, bankId, accountNumber, bankLoginId, ipAddress}) {
    const data = {accountNumber, bankLoginId, saveAccountNumber : false};
    return await axiosInstancePublicApi.post("/consent/authorize/"+bankId, JSON.stringify(data), getBasicOptions(false, token, ipAddress));
}

async function checkConsentStatus({token, consentId}) {
    return await axiosInstancePublicApi.get("/consent/mitigate/status/"+consentId, getBasicOptions(false, token));
}

async function rejectConsent({token, reason}) {
    const data = {reason: reason};
    return await axiosInstancePublicApi.post("/mitigate/public/reject", JSON.stringify(data), getBasicOptions(false, token));
}

async function enableRecash(token) {
    const data = {consent: true};
    return await axiosInstancePublicApi.patch("/mitigate/public/recash", JSON.stringify(data), getBasicOptions(false, token));
}

export default {
    getConfigData, getClientData, validateClientData, getBanks, generateAuthorization, checkConsentStatus, rejectConsent, enableRecash
};