class QueryUtilsC {

    getClientFromPath(path) {
        if(!path) return null;
        let splitted = path.split("client=");
        if(splitted.length < 2) return null;
        return splitted[1];
    }

}

const QueryUtils = new QueryUtilsC();

export {
    QueryUtils 
};