import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UrlUtils } from "../../util/UrlUtils";
import {ReactComponent as SuccessLogo} from '../../assets/img_state_syncing_success.svg';
import { ColorUtils } from "../../util/ColorUtils";
import OrangeButton from "../common/OrangeButton";

export default({clientToken, selectedBank, loading, setLoading, onClose}) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const bankList = useSelector(state => state.commonReducer.banks);
    const bankData = bankList?.data?.banks.filter(i => i.id == selectedBank)?.pop();
    const image = UrlUtils.getImageUrl(bankData?.logo?.url);
    return(
        <Stack sx={{mt: 2}}>
            <img style={{maxWidth: '200px', height: '70px', margin: 'auto'}} src={image} />
            <h5 style={{margin: 'auto', marginTop: '16px', color: '#1D0F32'}}>{bankData?.name}</h5>
            <SuccessLogo style={{margin: 'auto', marginTop: '16px'}} />
            <h4 style={{margin: 'auto', marginTop: '16px', color: ColorUtils.getPrimaryBrandColor(theme)}}>{t('bank_success_title')}</h4>
            <Box className="regular18" sx={{textAlign: 'center', m: 'auto', mt: 2, mb: 2, maxWidth: '800px', textAlign: 'center', mb: 2}}>{t('bank_success')}</Box>
            <OrangeButton sx={{display: 'none', m: 'auto', minHeight: '49px', paddingLeft: '36px', paddingRight: '36px', mb: 2}} variant='contained'>{t('bank_success_next')}</OrangeButton>
        </Stack>
    );
}