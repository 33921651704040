import { config as apiConfig, selectedProfile } from '../config/config';

class UrlUtilsC {

    getImageUrl(path) {
        if(!path) return "";
        if(path.startsWith("/") || path.startsWith('\\')) {
            path = path.substring(1, path.length);
        }
        return apiConfig[selectedProfile].STATIC_API_BASE_URL+'/'+path;
    }

    getImageUrlFromId(path) {
        return apiConfig[selectedProfile].STATIC_API_BASE_URL+"/image/"+path;
    }

}

const UrlUtils = new UrlUtilsC();

export {
    UrlUtils
}