import './App.css';
import { Fragment, useState } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import StoreProvider from './store/StoreProvider';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n'; 
import MainLayout from './ui/MainLayout';
import StatosferaThemeProvider from './theme/StatosferaThemeProvider';
import { config as apiConfig, selectedProfile } from './config/config';
import { useTranslation } from 'react-i18next';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#8800ff'
    },
    secondary: {
      main: '#E33E7F'
    }
  },
  branding: {
    pageTitle: '',
    brandName: '',
    primaryBrandColor: '#8800ff',
    secondaryBrandColor: '#E33E7F',
    logoUrl: "https://place-hold.it/144x62",
    bannerUrl: "https://place-hold.it/1128x180",
    rejectEnabled: false, 
    showTerms: false,
    termsLink: null
  }
});

const mergeTheme = (baseTheme, apiTheme) => {
  const mergedProperties = {branding: {}};
  if(apiTheme) {
    const currentLanguage = i18n.language;
    if(apiTheme.texts?.pageTitle) {
      if(apiTheme.texts?.pageTitle[currentLanguage]) {
        mergedProperties.branding["pageTitle"] = apiTheme.texts?.pageTitle[currentLanguage];
      }
    }
    if(apiTheme.texts?.brandName) {
      if(apiTheme.texts?.brandName[currentLanguage]) {
        mergedProperties.branding["brandName"] = apiTheme.texts?.brandName[currentLanguage];
      }
    }
    if(apiTheme.primaryBrandColor) {
      mergedProperties.branding["primaryBrandColor"] = apiTheme.primaryBrandColor;
    }
    if(apiTheme.secondaryBrandColor) {
      mergedProperties.branding["secondaryBrandColor"] = apiTheme.secondaryBrandColor;
    }
    if(apiTheme.brandLogoId) {
      mergedProperties.branding["logoUrl"] = apiConfig[selectedProfile].STATIC_API_BASE_URL+"/image/"+apiTheme.brandLogoId;
    }
    if(apiTheme.brandBannerId) {
      mergedProperties.branding["bannerUrl"] = apiConfig[selectedProfile].STATIC_API_BASE_URL+"/image/"+apiTheme.brandBannerId;
    }
    if(apiTheme.rejectEnabled) {
      mergedProperties.branding["rejectEnabled"] = true;
    }
    if(apiTheme.showTerms) {
      mergedProperties.branding["showTerms"] = true;
    }
    if(apiTheme.links?.termsLink) {
      if(apiTheme.links?.termsLink[currentLanguage]) {
        mergedProperties.branding["termsLink"] = apiTheme.links?.termsLink[currentLanguage];
      }
    }
  }
  return Object.assign({}, baseTheme, mergedProperties);
}

function App() {
  const [theme, setTheme] = useState(defaultTheme);
  const {t, i18n} = useTranslation();
  const [apiTheme, setApiTheme] = useState(null);
  const mergedTheme = mergeTheme(theme, apiTheme);

  return (
    <Fragment>
      <BrowserRouter>
        <StoreProvider>
          <CssBaseline />
          <ThemeProvider theme={mergedTheme}>
            <StatosferaThemeProvider theme={mergedTheme} apiTheme={apiTheme} setApiTheme={setApiTheme}>
              <MainLayout />
            </StatosferaThemeProvider>
          </ThemeProvider >
        </StoreProvider>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
