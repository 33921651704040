import { Divider, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ColorUtils } from "../../../util/ColorUtils";

export default({title, text, text2, text3}) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    if(!title) {
        title = t('bank_select');
    }
    if(!text) {
        text = t('bank_select_desc');
    }
    if(!text2) {
        text2 = "";
    }
    return(
        <>
            <Typography variant='h5' color="primary" gutterBottom sx={{color: ColorUtils.getPrimaryBrandColor(theme)}}>{title}</Typography>
            <span className="medium18" style={{display:'inline-block', width: '100%', boxSizing: 'border-box'}}>{text}</span><br/><br/>
            <span className="regular16" style={{display:'inline-block', width: '100%', boxSizing: 'border-box'}}>{text2}</span><br/>
            <span className="regular16" style={{width: '100%', display: 'inline-block', overflowWrap:'break-word', marginTop: '16px'}}></span>
            {text3 && 
            <>
            <span className="regular16" style={{display:'inline-block', width: '100%', boxSizing: 'border-box'}}>{text3}</span><br/>
            <span className="regular16" style={{width: '100%', display: 'inline-block', overflowWrap:'break-word', marginTop: '16px'}}></span>
            </>
            }
            
            <Divider sx={{mt: 4}} flexItem />
        </>
    );
}