import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UrlUtils } from "../../util/UrlUtils";
import {ReactComponent as SyncLogo} from '../../assets/img_state_syncing.svg';
import { ColorUtils } from "../../util/ColorUtils";
import OrangeButton from "../common/OrangeButton";

export default({clientToken, selectedBank, loading, setLoading, onPrevious, onNext, activeUrl}) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const bankList = useSelector(state => state.commonReducer.banks);
    const bankData = bankList?.data?.banks.filter(i => i.id == selectedBank)?.pop();
    const image = UrlUtils.getImageUrl(bankData?.logo?.url);
    return(
        <Stack sx={{mt: 2}}>
            <img style={{maxWidth: '200px', height: '70px', margin: 'auto'}} src={image} />
            <h5 style={{margin: 'auto', marginTop: '16px', color: '#1D0F32'}}>{bankData.name}</h5>
            <SyncLogo style={{margin: 'auto', marginTop: '16px', animation: 'fadeInLogo 2s infinite'}} />
            <h4 style={{margin: 'auto', marginTop: '16px', color: ColorUtils.getPrimaryBrandColor(theme)}}>{t('working')}</h4>
            <Box className="regular18" sx={{textAlign: 'center', m: 'auto', mt: 2, mb: 0}}>{t('please_wait')}</Box>
            {activeUrl && 
            <>
                <Box className="regular14" sx={{textAlign: 'center', m: 'auto', mt: 2, mb: 0}}>{t('window_open_text')}</Box>
                <OrangeButton href={activeUrl} target="consentWindow" sx={{m: 'auto', minHeight: '49px', paddingLeft: '36px', paddingRight: '36px', mt: 2, mb: 4}} variant='contained'>{t('continue')}</OrangeButton>
            </>
            }
        </Stack>
    );
}