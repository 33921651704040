import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UrlUtils } from "../../util/UrlUtils";
import { ColorUtils } from "../../util/ColorUtils";
import LogosComponent from "./ui/LogosComponent";
import DescriptionComponent from "./ui/DescriptionComponent";
import { QueryUtils } from '../../util/QueryUtils';
import { validateClientDataRequest } from "../../reducer/CommonReducer";
import { useLocation } from "react-router";
import { useFormik } from "formik";
import * as Yup from 'yup';
import OrangeButton from "../common/OrangeButton";
import WhiteButton from "../common/WhiteButton";

export default({clientToken, selectedBank, loading, setLoading, onPrevious, onNext}) => {
    const theme = useTheme();
    const dispatcher = useDispatch();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    
    const bankList = useSelector(state => state.commonReducer.banks);
    const bankData = bankList?.data?.banks.filter(i => i.id == selectedBank)?.pop();
    const image = UrlUtils.getImageUrl(bankData?.logo?.url);
    const requiresAccount = bankData?.requiresAccount;
    const requiresBankLoginId = bankData?.requiresBankLoginId;

    const primaryColor = ColorUtils.getPrimaryBrandColor(theme);
    const brandName = theme.branding.brandName;
    const showTerms = theme.branding.showTerms;
    const termsLink = theme.branding.termsLink;

    let bankLoginIdName = t('bank_login_id');
    let bankLoginIdLabel = t('bank_login_id_input');
    let bankLoginIdDesc = t('bank_login_id_desc');
    if(bankData?.swiftCode == "RZBHHR2X" || bankData?.swiftCode == "HPBZHR2X") {
        bankLoginIdLabel = t('bank_login_id_input_hr');
        bankLoginIdDesc = t('bank_login_id_desc_hr');
    }
    else if(bankData?.swiftCode == "OTPVHR2X") {
        bankLoginIdLabel = t('bank_login_id_input_hr_otp');
        bankLoginIdDesc = t('bank_login_id_desc_hr_otp');
    }

    const textDesc = <div dangerouslySetInnerHTML={
        {__html: t('bank_select_desc3', {interpolation: {escapeValue: false}})}
    } />;

    const [validationErrors, setValidationErrors] = useState({
        invalidaccount: {error: false, message: null}
    })

    const nonHuBanksThatRequireAccountNumber = ["BACXCZPP", "ZABAHR2X", "PBZGHR2X"];

    const formik = useFormik({
        initialValues: {
            accountNumber: '',
            bankLoginId: '',
            termsAccepted: false
        },
        validationSchema: Yup.object({
            accountNumber: Yup.string()
                .test('isRequired', t('account_number_required'), val => {
                    if(!requiresAccount) return true;
                    return val;
                })
                .test('isValidAccountNumber', t('account_number_not_numeric'), val => {
                    if(!requiresAccount) return true;
                    if(nonHuBanksThatRequireAccountNumber.includes(bankData?.swiftCode)) {
                        return true;
                    }
                    return val && val.trim().match(/^\d+$/);
                })
                .test('isAccountNumberProperLength', t('account_number_length'), val => {
                    if(!requiresAccount) return true;
                    if(nonHuBanksThatRequireAccountNumber.includes(bankData?.swiftCode)) {
                        return true;
                    }
                    return val && (val.trim().length == 16 || val.trim().length == 24);
                })
                .test('isAccountNumberProperFormatCz', t('account_number_format_cz'), val => {
                    if(!requiresAccount) return true;
                    if(bankData?.swiftCode == "BACXCZPP") {
                        let reg = /^(\d){10}\/(\d){4}$/;
                        return val && val.trim().match(reg);
                    }
                    return true;
                })
                .test('isAccountNumberProperFormatHr', t('account_number_format_hr'), val => {
                    if(!requiresAccount) return true;
                    if(bankData?.swiftCode == "ZABAHR2X" || bankData?.swiftCode == "PBZGHR2X") {
                        let reg = /^(\d){17}$/;
                        return val && val.trim().match(reg);
                    }
                    return true;
                }),
            bankLoginId: Yup.string()
                .max(64)
                .test('isRequired', t('bank_login_id_required'), val => {
                    if(!requiresBankLoginId) return true;
                    return val;
                })
                .test('isLoginIdProperFormatHu', t('bank_login_id_format_hu'), val => {
                    if(!requiresBankLoginId) return true;
                    if(bankData?.swiftCode == "UBRTHUHB") {
                        let reg = /^(\d){8}$/;
                        return val && val.trim().match(reg);
                    }
                    return val;
                })
                .test('isLoginIdProperFormatHr', t('bank_login_id_format_hr'), val => {
                    if(!requiresBankLoginId) return true;
                    if(bankData?.swiftCode == "RZBHHR2X" || bankData?.swiftCode == "HPBZHR2X") {
                        let reg = /^(\d){11}$/;
                        return val && val.trim().match(reg);
                    }
                    return val;
                })
                .test('isLoginIdProperFormatHr2', t('bank_login_id_format_hr_otp'), val => {
                    if(!requiresBankLoginId) return true;
                    if(bankData?.swiftCode == "OTPVHR2X") {
                        let reg = /^(\d){10}$/;
                        return val && val.trim().match(reg);
                    }
                    return val;
                })
                ,
            termsAccepted: Yup.boolean().test('mustCheck', t('please_accept_terms'), val => {
                if(!showTerms) {
                    return true;
                }
                if(showTerms && !termsLink) {
                    return true;
                }
                return val;
            })
        }),
        onSubmit: values => {
            const {accountNumber, bankLoginId, termsAccepted} = values;
            const clientToken = QueryUtils.getClientFromPath(location?.search);
            if(requiresAccount) {
                const data = {token: clientToken, accountNumber : accountNumber.trim(), bankId: selectedBank};
                validationErrors.invalidaccount.error = false;
                dispatcher(validateClientDataRequest(data)).unwrap().then(res => {
                    if(res?.success) {
                        if(res.data?.valid) {
                            onNext(bankLoginId, accountNumber);
                        }
                        else {
                            validationErrors.invalidaccount.error = true;
                            validationErrors.invalidaccount.message = t('invalid_account_number');
                            setValidationErrors({...validationErrors});
                        }
                    }
                });
                return;
            }
            onNext(bankLoginId, accountNumber);
        },
      });

    useEffect(() => {
        const body = document.querySelector('#bankInfo');
        body.scrollIntoView({behavior: 'smooth'}, 500);
    }, []);

    const handleAccountNumberPaste = (e) => {
        if(!e || !e.clipboardData) {
            return;
        }
        let pastedValue = e.clipboardData.getData('text/plain');
        if(!pastedValue) return;
        pastedValue = pastedValue.trim();
        pastedValue = pastedValue.replaceAll("-", "");
        pastedValue = pastedValue.replaceAll(" ", "");
        formik.setFieldValue('accountNumber', pastedValue);
        e.preventDefault();
    }

    return(
        <Box sx={{p: 1, pt: 2}}>
            <Grid container direction={{ xs: 'column', md: 'row' }}>
                <Grid item xs={6} sx={{pl: {xs: 0, md: 2}, pr: {xs: 0, md: 2}}}>
                    <DescriptionComponent title={t('menu_2')} text={textDesc} text2={t('bank_select_desc4')} text3={t('bank_select_desc5')} />
                    <LogosComponent />
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid item xs={6} sx={{pl: {xs: 0, md: 2}, pr: {xs: 0, md: 2}}}>
                    <form id="bankInfo" onSubmit={formik.handleSubmit}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{mt: 4}}>
                        <Stack>
                            <span className="regular14">{t('chosen_bank')}</span>
                            <h5 style={{color: '#1D0F32', margin:0, padding: 0, marginTop: '4px'}}>{bankData.name}</h5>
                        </Stack>
                        <Box>
                            <img src={image} style={{height: '70px'}}/>
                        </Box>
                    </Stack>
                    <Divider sx={{mt: 2}} />
                    {(requiresAccount || requiresBankLoginId) && 
                        <>
                        <Box className="regular18" sx={{mt: 2}}>{t('please_enter_data')}</Box>
                        {requiresAccount && 
                            <Stack>
                                <Box className="regular14" sx={{mt: 2, color: '#1A1A1A'}}>{t('bank_account_number')}</Box>
                                <Stack direction="row" sx={{mt: 1}} justifyContent="space-between" alignItems="flex-start">
                                    <TextField 
                                        id="accountNumber"
                                        name="accountNumber"
                                        type="text"
                                        sx={{width: '100%'}}
                                        label={t('bank_account_number')}
                                        variant="outlined"
                                        onPaste={handleAccountNumberPaste}
                                        onChange={formik.handleChange}
                                        value={formik.values.accountNumber}
                                        error={!!formik.errors.accountNumber}
                                        helperText={formik.errors.accountNumber} />
                                </Stack>
                            </Stack>
                        }
                        {requiresBankLoginId && 
                            <Stack sx={{mt: 2}}>
                                <Box className="regular14" sx={{color: '#1A1A1A'}}>{bankLoginIdName}</Box>
                                <TextField 
                                    id="bankLoginId"
                                    name="bankLoginId"
                                    type="text"
                                    sx={{mt: 1, width: '100%'}}
                                    label={bankLoginIdLabel}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.bankLoginId}
                                    error={!!formik.errors.bankLoginId}
                                    helperText={formik.errors.bankLoginId}
                                    />
                                <Box className="medium14" sx={{mt: 2}}>{bankLoginIdDesc}</Box>
                            </Stack>
                        }
                    </>
                    }
                    {showTerms && termsLink && 
                    <>
                    <Stack direction="row" alignItems="flex-start" sx={{mt: 1}}>
                        <FormControlLabel
                            control={<Checkbox id="acceptedTerms" sx={{color: ColorUtils.getPrimaryBrandColor(theme), '&.Mui-checked': {color: ColorUtils.getPrimaryBrandColor(theme)}}} />}
                            name="acceptedTerms"
                            checked={formik.values.termsAccepted}
                            onChange={(e) => {
                                formik.setFieldValue("termsAccepted", e.target.checked);
                            }}
                        />
                        <Box className="regular14 termsLink" sx={{m: 'auto', ml: 0, color: '#1A1A1A'}}>
                            <Trans i18nKey="accept_terms_text">
                                <span style={{textAlign: 'left'}}>Hello {{brandName}}, welcome to our website! Click <a href={termsLink} target="new" style={{display: 'inline'}}>here</a> to learn more.</span>
                            </Trans>
                        </Box>
                    </Stack>
                    {!!formik.errors.termsAccepted && 
                        <p style={{margin: 0, marginLeft: '12px', color: '#d32f2f', fontWeight: '400', fontSize: '0.75rem', lineLeight: '1.66',letterSpacing: '0.03333em'}}>
                            {formik.errors.termsAccepted}
                        </p>
                    }
                    </>
                    }
                    {validationErrors.invalidaccount.error && 
                        <p style={{margin: 0, marginLeft: '12px', color: '#d32f2f', fontWeight: '400', fontSize: '0.75rem', lineLeight: '1.66',letterSpacing: '0.03333em'}}>
                            {validationErrors.invalidaccount.message}
                        </p>
                    }
                    <Grid container spacing={1} direction={{ xs: 'column', md: 'row' }} sx={{mt: 2, pr: 1, pl: 1}} >
                        <Grid item xs={6} order={{xs: 2, md: 1}}>
                            <WhiteButton sx={{width: '100%', minHeight: '49px'}} variant='contained' onClick={onPrevious}>{t('back')}</WhiteButton>
                        </Grid>
                        <Grid item xs={6} order={{xs: 1, md: 2}}>
                            <OrangeButton sx={{width: '100%', ml: '4%', minHeight: '49px'}} variant='contained' type="submit">{t('do_credit_check')}</OrangeButton>
                        </Grid>
                    </Grid>
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
}