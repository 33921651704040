import { Box, Divider, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getBanksRequest } from "../../reducer/CommonReducer";
import BankGridComponent from "./ui/BankGridComponent"
import LogosComponent from "./ui/LogosComponent";
import DescriptionComponent from "./ui/DescriptionComponent";

export default({clientToken, loading, setLoading, onPrevious, onBankSelected, onReject}) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const dispatcher = useDispatch();
    const bankData = useSelector(state => state.commonReducer.banks);
    useEffect(() => {
        if(!bankData && !loading) {
            setLoading(true);
            dispatcher(getBanksRequest(clientToken)).unwrap().then(res => {
                setLoading(false);
            }, e => {});
        }
    });
    const textDesc = <div dangerouslySetInnerHTML={
        {__html: t('bank_select_desc2', {interpolation: {escapeValue: false}})}
    } />;
    return (
        <Box sx={{p: 1, pt: 2}}>
            <Grid container direction={{ xs: 'column', md: 'row' }}>
                <Grid item xs={6} sx={{pl: {xs: 0, md: 2}, pr: {xs: 0, md: 2}}}>
                <DescriptionComponent title={t('bank_select')} text={t('bank_select_desc')} text2={textDesc} />
                <LogosComponent />
                </Grid>
                <Divider flexItem orientation="vertical" />
                <Grid item xs={6} sx={{pl: {xs: 0, md: 2}, pr: {xs: 0, md: 2}}}>
                    <BankGridComponent banks={bankData} onBankSelected={onBankSelected} onReject={onReject} />
                </Grid>
            </Grid>    
        </Box>
    );
}