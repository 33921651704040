import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ColorUtils } from "../../util/ColorUtils";

const OrangeButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: ColorUtils.getSecondaryBrandColor(theme),
    borderRadius: '8px',
    textTransform: 'none',
    fontFamily: 'Switzer',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '0.2px',
    '&:hover': {
      backgroundColor: ColorUtils.toRgbaWithOpacity(ColorUtils.getSecondaryBrandColor(theme), 0.8),
    },
  }));

export default OrangeButton;