import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "hu",
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          menu_1: 'Selecting bank',
          menu_2: 'Enter your data',
          menu_3: 'Validation',
          main_title: 'Credit rating platform',
          main_subtitle: 'Credit rating in seconds!',
          main_subtitle2: 'Free service without any commitment',
          hello: 'Hello',
          next: 'Next',
          restart: 'Try again',
          step: 'Step',
          back: 'Back',
          check_your_data: 'Please check your data then click next if correct',
          bank_select: 'Select your bank',
          bank_select_desc: 'We will generate your Solvency Report based on the information from your bank. ',
          bank_select_desc2: '1. Select your bank<br/><br/>2. Log in to your bank directly<br/><br/>3. Your bank will walk you through the process of authorising STATOSFERA.',
          bank_select_desc3: 'STATOSFERA is an Account Information Service Provider Financial Institution registered in accordance with the European Union Directive 2015/2366 ("PSD2") in all EU Member States. ',
          bank_select_desc4: 'It performs its activities under central bank control and handles your data with bank-level security.',
          bank_select_desc5: 'We would like to inform you that STATOSFERA process your data only once when your financial health assessment is requested.',
          bank_data_input_desc: 'Click on \"Next\" and we redirect you to your bank\'s website. This will open a new window. Please follow the instructions there. DO NOT close and DO NOT refresh this window',
          bank_waiting: 'Waiting for results',
          bank_waiting_desc: 'Please follow the instructions on your bank\'s website. DO NOT refresh this window.',
          bank_error: 'Error',
          bank_error_desc: 'Unfortunately there was an error',
          bank_success: 'Success',
          bank_success_desc: 'All good',
          close_window: 'Close',
          you_selected: 'Selected bank',
          full_name: 'Full legal name',
          legal_name: 'Legal name',
          email: 'E-mail address',
          phone: 'Phone number',
          dob: 'Date of birth',
          address: 'Address',
          country_code_hu: 'Hungary',
          client_type: 'Client type',
          client_type_personal: 'Personal account',
          client_type_business: 'Business account',
          all_rights: 'All rights reserved ⓒ 2023 - Statosfera',
          terms_of_service: 'Terms of service',
          impressum: 'Impressum',
          cookie_policy: 'Cookie policy',
          not_available: 'Currently not available',
          bank_error_title: 'There was an error',
          bank_error: 'We\'re sorry, but there was an error during the process. Please contact our support.',
          bank_success_title: 'Success!',
          bank_success: 'You have no other steps to complete.',
          bank_success_next: 'Continue to the website of the provider',
          european_banking_authority: 'European Banking Authority',
          aisp_licence: 'AISP Registered Lincence',
          powered_by_statosfera: 'Powered by Statosfera',
          chosen_bank: 'Chosen bank',
          cant_be_empty: 'Can\'t be empty',
          must_be_long: 'This field must be at least 8 characters long',
          please_accept_terms: 'Please accept the terms to continue',
          please_enter_data: 'Please enter the following data to start the process',
          bank_account_number: 'Bank account number',
          bank_login_id: 'Bank login ID',
          bank_login_id_input: 'Direkt ID',
          bank_login_id_input_hr: 'OIB number',
          bank_login_id_input_hr_otp: 'mToken number',
          bank_login_id_desc: 'To authorize your consent the Direkt ID you use to log in to your bank is needed.',
          bank_login_id_desc_hr: 'To authorize your consent your personal OIB number is needed for the bank',
          bank_login_id_desc_hr_otp: 'To authorize your consent your personal mToken number is needed for the bank',
          bank_account_number_part_1: 'Part 1',
          do_credit_check: 'Create report',
          accept_terms_text: '<0>I consent that <3>STATOSFERA</3>, as data processor and data controller, have access to my bank account data for the 90-day period preceding such consent solely for the purpose of providing payment services (solvency test).</0>',
          working: 'Working...',
          please_wait: 'Please don\'t close this window while we generate your report. It only takes a minute.',
          invalid_account_number: 'This account number is invalid. Please check again.',
          account_number_required: 'Account number is required for this bank',
          account_number_not_numeric: 'Account number must only contain numbers',
          account_number_length: 'The account number must be 16 or 24 characters long',
          account_number_format_cz: 'The account number must be in proper format',
          account_number_format_hr: 'The account number must be in proper format (10 digits)',
          bank_login_id_required: 'Your bank login ID is required for this bank',
          bank_login_id_format_hu: 'The bank login ID must be in proper format (8 digits)',
          bank_login_id_format_hr: 'The OIB number must be in proper format (11 digits)',
          bank_login_id_format_hr_otp: 'The mToken number must be in proper format (10 digits)',
          window_open_text: 'If a new window did not appear please click here to continue:',
          continue: 'Continue',
          bank_not_on_the_list: 'My bank is not on the list',
          refuse_consent: 'I don\'t want to give consent'
        }
      },
      hu: {
        translation: {
          menu_1: 'Bank kiválasztása',
          menu_2: 'Adatok megadása',
          menu_3: 'Ellenőrzés',
          main_title: 'Hitelminősítő platform',
          main_subtitle: 'Azonnali minősítés másodperceken belül!',
          main_subtitle2: 'Ingyenes  szolgáltatás, kötelezettségek nélkül',
          hello: 'Hello',
          next: 'Következő',
          restart: 'Próbálkozzunk újra',
          step: 'Lépés',
          back: 'Vissza',
          check_your_data: 'Please check your data then click next if correct',
          bank_select: 'Bankválasztás',
          bank_select_desc: 'A bankodtól megkapott adatokból rendszerünk létrehozza fizetőképességi megítélésedet.',
          bank_select_desc2: '1. Válaszd ki bankodat, ahova átirányításra kerülsz<br/><br/>2. Bankod oldalán a megszokott azonosítóval és jelszóval kell belépned<br/><br/>3. Belépést követően a jóváhagyás megadásán kívül egyéb teendőd nincs.',
          bank_select_desc3: 'A STATOSFERA az Európai Unió 2015/2366 számú direktívája ("PSD2") alatt, az EU összes tagállamában regisztrált Számlainformációs Szolgáltató Pénzügyi Intézmény. ',
          bank_select_desc4: 'Tevékenységét jegybanki ellenőrzés mellett látja el, adataidat pedig banki szintű biztonsággal kezeli.',
          bank_select_desc5: 'Tájékoztatunk, hogy a hozzájárulásod alapján a fizetőképességed megítélése céljából történő adatkezelésre egyszer kerül sor, amikor is fizetőképességi besorolásodat a STATOSFERA elkészíti.',
          bank_data_input: 'Redirecting you',
          bank_data_input_desc: 'Click on \"Next\" and we redirect you to your bank\'s website. This will open a new window. Please follow the instructions there. DO NOT close and DO NOT refresh this window',
          bank_waiting: 'Waiting for results',
          bank_waiting_desc: 'Please follow the instructions on your bank\'s website. DO NOT refresh this window.',
          bank_error: 'Error',
          bank_error_desc: 'Unfortunately there was an error',
          bank_success: 'Success',
          bank_success_desc: 'All good',
          close_window: 'Close',
          you_selected: 'Selected bank',
          full_name: 'Full legal name',
          legal_name: 'Legal name',
          email: 'E-mail address',
          phone: 'Phone number',
          dob: 'Date of birth',
          address: 'Address',
          country_code_hu: 'Hungary',
          client_type: 'Client type',
          client_type_personal: 'Personal account',
          client_type_business: 'Business account',
          all_rights: 'All rights reserved ⓒ 2023 - Statosfera',
          terms_of_service: 'Terms of service',
          impressum: 'Impressum',
          cookie_policy: 'Cookie policy',
          not_available: 'Jelenleg nem elérhető',
          bank_error_title: 'Valami nem sikerült',
          bank_error: 'Sajnáljuk, de valamiért nem tudtuk átnézni a bankszámlád. Amennyiben többször tapasztalatod ezt a problémát, kérjük, vedd fel a kapcsolatot Ügyfélszolgálatunkkal!',
          bank_success_title: 'Szuper, köszönjük, minden rendben!',
          bank_success: 'Nincs további teendőd!',
          bank_success_next: 'Tovább a szolgáltató honlapjára',
          european_banking_authority: 'European Banking Authority',
          aisp_licence: 'AISP Registered Lincence',
          powered_by_statosfera: 'Powered by Statosfera',
          chosen_bank: 'Válaszott bank',
          cant_be_empty: 'Ez a mező kötelező',
          must_be_long: 'Eza mező 8 karakter hosszú',
          please_accept_terms: 'Kérlek fogadd el a szabályzatot',
          please_enter_data: 'Kérjük add meg az alábbi adatokat, hogy elindíthassuk az összekötést.',
          bank_account_number: 'Bankszámlaszám',
          bank_login_id: 'Felhasználói azonosító',
          bank_login_id_input: 'Direct ID',
          bank_login_id_input_hr: 'OIB szám',
          bank_login_id_desc: 'A bankszámla azonosításához meg kell adnod a Netbankodhoz használt Direkt ID-t is!',
          bank_login_id_desc_hr: 'A bankszámla azonosításához meg kell adnod az OIB számodat',
          bank_login_id_desc_hr_otp: 'A bankszámla azonosításához meg kell adnod az mToken számodat',
          bank_account_number_part_1: 'Első 8',
          bank_account_number_part_2: 'Második 8',
          bank_account_number_part_3: 'Harmadik 8',
          do_credit_check: 'Minősítés elvégzése',
          accept_terms_text: '<0>Kijelentem, hogy az <3>Adatkezelési Tájékoztatót</3> elolvastam, és hozzájárulok az adataim jelen tájékoztatóban írtak szerinti kezeléséhez.</0>',
          working: 'Dolgozunk...',
          please_wait: 'Kérjük várj amíg végzünk, és addig ne zárd be a böngészőablakot!',
          invalid_account_number: 'A számlaszám nem érvényes számlaszám, kérlek ellenőrizd még egyszer',
          account_number_required: 'A bankodnál kötelező a számlaszám megadása a folyamat indításához',
          account_number_not_numeric: 'A számlaszám csak számot tartalmazhat',
          account_number_length: 'A számlaszám 16 vagy 24 karakter hosszú kell hogy legyen',
          account_number_format_cz: 'A számlaszám nem megfelelő formátumú',
          account_number_format_hr: 'A számlaszám nem megfelelő formátumú (10 számjegy)',
          bank_login_id_required: 'A bankodnál a banki azonosítód megadása kötelező a folyamat indításához',
          bank_login_id_format_hu: 'A banki azonosító nem megfelelő formátumú (8 számjegy)',
          bank_login_id_format_hr: 'Az OIB azonosító nem megfelelő formátumú (11 számjegy)',
          bank_login_id_format_hr_otp: 'Az mToken azonosító nem megfelelő formátumú (10 számjegy)',
          window_open_text: 'Amennyiben nem nyílt meg egy új ablak kérlek kattints ide a folytatáshoz:',
          continue: 'Folytatás',
          bank_not_on_the_list: 'A bankom nincs a listán',
          refuse_consent: 'Nem szeretnék engedélyt adni'
        }
      },
      cz: {
        translation: {
          menu_1: 'Výběr banky',
          menu_2: 'Vaše údaje',
          menu_3: 'Kontrola',
          main_title: 'Platforma úvěrového ratingu',
          main_subtitle: 'Rating během několika sekund!',
          main_subtitle2: 'Bezplatná služba bez závazků',
          hello: 'Vítejte!',
          next: 'Další',
          restart: 'Skuste znovu',
          step: 'Krok',
          back: 'Zpět',
          check_your_data: 'Zkontrolujte prosím svá data a pokud jsou správné, klikněte na Další',
          bank_select: 'Vyberte svou banku',
          bank_select_desc: 'Z údajů, které obdržíme od Vaší banky, náš systém vygeneruje report o vaší solventnosti. ',
          bank_select_desc2: '1. Vyberte si svou banku, do které budete přesměrováni<br/><br/>2. Na webu Vaší banky se přihlaste jako obvykle, svým uživatelským jménem a heslem <br/><br/>3. Po přihlášení už stači jen váš souhlas.',
          bank_select_desc3: ' STATOSFERA je finanční instituce poskytující služby informování o platebním účtu ("Informační služby o účtu") registrovaná ve všech členských státech EU podle směrnice 2015/2366 Evropské unie („PSD2“).',
          bank_select_desc4: ' Je plně pod kontrolou centrální banky a Vaše data jsou zpracována v souladu se zabezpečením na úrovni banky.',
          bank_select_desc5: 'Rádi bychom vás informovali, že zpracování údajů probíhá jednorázově, a to při posuzování vašeho finančního zdraví společností STATOSFERA.',
          bank_data_input: 'Přesměrování',
          bank_data_input_desc: 'Klikněte na \"Další\"a přesměrujeme vás na web vaší banky. Tím se otevře nové okno. Postupujte prosím podle pokynů v něm. Toto okno NEzavírejte a NEobnovujte.',
          bank_waiting: 'Čekání na výsledky',
          bank_waiting_desc: 'Postupujte podle pokynů na webu vaší banky. NEOBNOVUJTE toto okno prosím!.',
          bank_error: 'Vyskytla se chyba',
          bank_error_desc: 'Bohužel se vyskytla chyba',
          bank_success: 'Výborně!',
          bank_success_desc: 'Vše v pořádku',
          close_window: 'Zavřít',
          you_selected: ' Vybraná banka',
          full_name: 'Obchodní jméno',
          legal_name: 'Jméno',
          email: 'Váš e-mail',
          phone: 'Telefónní číslo',
          dob: 'Datum narození',
          address: 'Adresa',
          country_code_hu: 'Česká republika',
          client_type: 'Typ klienta',
          client_type_personal: 'Osobní účet',
          client_type_business: 'Podnikatelský účet',
          all_rights: 'All rights reserved ? 2023 - Statosfera',
          terms_of_service: 'Podmínky služeb',
          impressum: 'Impressum',
          cookie_policy: 'Nastavení cookies',
          not_available: 'Momentálně není k dispozici',
          bank_error_title: 'Něco se nepovedlo',
          bank_error: 'Je nám líto, ale během operace došlo k chybě. Kontaktujte prosím naši podporu!', 
          bank_success_title: 'Děkujeme, vše je v pořádku!',
          bank_success: 'Výborně!',
          bank_success_next: 'Pokračovat na web poskytovatele služeb',
          european_banking_authority: ' European Banking Authority ',
          aisp_licence: 'AISP Registered Lincence',
          powered_by_statosfera: 'Powered by Statosfera',
          chosen_bank: 'Vybraná banka',
          cant_be_empty: 'Toto pole je povinné',
          must_be_long: 'Toto pole musí mít alespoň 8 znaků',
          please_accept_terms: 'Chcete-li pokračovat, přijměte prosím Zásady ochrany osobních údajů',
          please_enter_data: 'Pro zahájení procesu zadejte prosím následující údaje',
          bank_account_number: 'Čislo účtu',
          bank_login_id: 'Uživatelské jméno',
          bank_login_id_input: 'Klientské číslo',
          bank_login_id_input_hr: 'OIB number',
          bank_login_id_desc: ' K autorizaci vašeho souhlasu je zapotřebí vaše klientské číslo, které používáte k přihlášení do své banky!',
          bank_login_id_desc_hr: 'To authorize your consent your personal OIB number is needed for the bank',
          bank_login_id_desc_hr_otp: 'To authorize your consent your personal mToken number is needed for the bank',
          bank_account_number_part_1: 'První 8',
          bank_account_number_part_2: 'Druhý 8',
          bank_account_number_part_3: 'Třetí 8',
          do_credit_check: 'Generovat report',
          accept_terms_text: '<0> Prohlašuji, že jsem si přečetl/a <3>Zásady ochrany osobních údajů</3> a souhlasím se zpracováním mých údajů.</0>',
          working: 'Právě na tom pracujeme...',
          please_wait: 'Během generování reportu prosím nezavírejte toto okno. Tato operace trvá jen minutku!',
          invalid_account_number: 'Zadané číslo účtu je nesprávné. Zkontrolujte prosím znovu.',
          account_number_required: 'Pro zahájení procesu je nutné zadat číslo účtu ve vaší bance',
          account_number_not_numeric: 'Číslo účtu může obsahovat pouze číslo',
          account_number_length: 'Číslo účtu musí mít 16 nebo 24 znaků',
          account_number_format_cz: 'Číslo účtu musí být ve správném formátu',
          account_number_format_hr: 'Číslo účtu musí být ve správném formátu (10 číslic)',
          bank_login_id_required: 'K autorizaci vašeho souhlasu je zapotřebí vaše klientské číslo, které používáte k přihlášení do své banky.',
          bank_login_id_format_hu: 'vaše klientské číslo být ve správném formátu (8 číslic)',
          bank_login_id_format_hr: 'vaše klientské číslo být ve správném formátu (11 číslic)',
          bank_login_id_format_hr_otp: 'vaše klientské číslo být ve správném formátu (10 číslic)',
          window_open_text: 'Pokud se nezobrazilo nové okno, pokračujte kliknutím sem:',
          continue: 'Pokračovat',
          bank_not_on_the_list: 'Moje banka není na seznamu',
          refuse_consent: 'Nechci poskytnout souhlas'
        }
      },
      hr: {
        translation: {
          "menu_1": "Odabir banke",
          "menu_2": "Unesite svoje podatke",
          "menu_3": "Provjera",
          "main_title": "Platforma za kreditni rejting",
          "main_subtitle": "Kreditni rejting u sekundi!",
          "main_subtitle2": "Besplatna usluga bez ikakvih obveza",
          "hello": "Pozdrav",
          "next": "Dalje",
          "restart": "Pokušaj ponovno",
          "step": "Korak",
          "back": "Natrag",
          "check_your_data": "Molimo provjerite svoje podatke, a zatim kliknite dalje ako su točni",
          "bank_select": "Odaberite svoju banku",
          "bank_select_desc": "Izradit ćemo vaš Izvještaj o solventnosti na temelju informacija iz vaše banke.",
          "bank_select_desc2": "1. Odaberite svoju banku<br/><br/>2. Prijavite se izravno u svoju banku<br/><br/>3. Vaša banka će vas voditi kroz proces autorizacije STATOSFERE.",
          "bank_select_desc3": "STATOSFERA je pružatelj usluga informacija o računu registrirana financijska institucija u skladu s Direktivom Europske unije 2015/2366 ('PSD2') u svim državama članicama EU.",
          "bank_select_desc4": "Djeluje pod nadzorom središnje banke i upravlja vašim podacima s razinom sigurnosti kao u banci.",
          "bank_select_desc5": "Želimo vas obavijestiti da STATOSFERA obrađuje vaše podatke samo jednom kada je zatražena procjena vaše financijske situacije.",
          "bank_data_input_desc": "Kliknite na 'Dalje' i preusmjerit ćemo vas na web stranicu vaše banke. Otvorit će se novi prozor. Molimo slijedite tamošnje upute. NEMOJTE zatvoriti niti osvježiti ovaj prozor",
          "bank_waiting": "Čekanje rezultata",
          "bank_waiting_desc": "Molimo slijedite upute na web stranici vaše banke. NEMOJTE osvježavati ovaj prozor.",
          "bank_error": "Pogreška",
          "bank_error_desc": "Nažalost, došlo je do pogreške",
          "bank_success": "Uspjeh",
          "bank_success_desc": "Sve je u redu",
          "close_window": "Zatvori",
          "you_selected": "Odabrana banka",
          "full_name": "Puno pravno ime",
          "legal_name": "Pravno ime",
          "email": "E-mail adresa",
          "phone": "Broj telefona",
          "dob": "Datum rođenja",
          "address": "Adresa",
          "country_code_hu": "Mađarska",
          "client_type": "Tip klijenta",
          "client_type_personal": "Osobni račun",
          "client_type_business": "Poslovni račun",
          "all_rights": "Sva prava pridržana ⓒ 2023 - Statosfera",
          "terms_of_service": "Uvjeti korištenja",
          "impressum": "Impressum",
          "cookie_policy": "Politika kolačića",
          "not_available": "Trenutno nije dostupno",
          "bank_error_title": "Došlo je do pogreške",
          "bank_error": "Žao nam je, ali došlo je do pogreške tijekom procesa. Molimo kontaktirajte našu podršku.",
          "bank_success_title": "Uspjeh!",
          "bank_success": "Nemate drugih koraka za dovršiti.",
          "bank_success_next": "Nastavite na web stranicu pružatelja",
          "european_banking_authority": "Europska agencija za bankarstvo",
          "aisp_licence": "AISP registrirana licenca",
          "powered_by_statosfera": "Pokreće Statosfera",
          "chosen_bank": "Odabrana banka",
          "cant_be_empty": "Ne može biti prazno",
          "must_be_long": "Ovo polje mora imati najmanje 8 znakova",
          "please_accept_terms": "Molimo prihvatite uvjete kako biste nastavili",
          "please_enter_data": "Unesite sljedeće podatke kako biste započeli proces",
          "bank_account_number": "Broj bankovnog računa",
          "bank_login_id": "ID za prijavu u banku",
          "bank_login_id_input": "Direktni ID",
          "bank_login_id_input_hr": "OIB broj",
          "bank_login_id_input_hr_otp": "mToken broj",
          "bank_login_id_desc": "Za autorizaciju vašeg pristanka potreban je Direktni ID koji koristite za prijavu u banku.",
          "bank_login_id_desc_hr": "Za autorizaciju vašeg pristanka potreban je vaš osobni OIB broj za banku",
          "bank_login_id_desc_hr_otp": "Za autorizaciju vašeg pristanka potreban je vaš osobni mToken broj za banku",
          "bank_account_number_part_1": "Dio 1",
          "do_credit_check": "Izradi izvještaj",
          "accept_terms_text": "<0>Pristajem da <3>STATOSFERA</3>, kao obrađivač podataka i voditelj obrade, ima pristup mojim bankovnim podacima za razdoblje od 90 dana koje prethodi takvom pristanku isključivo u svrhu pružanja platnih usluga (test solventnosti).</0>",
          "working": "Radimo...",
          "please_wait": "Molimo ne zatvarajte ovaj prozor dok generiramo vaš izvještaj. Potrebna je samo minuta.",
          "invalid_account_number": "Ovaj broj računa nije valjan. Molimo provjerite ponovno.",
          "account_number_required": "Broj računa je potreban za ovu banku",
          "account_number_not_numeric": "Broj računa mora sadržavati samo brojeve",
          "account_number_length": "Broj računa mora biti dug 16 ili 24 znaka",
          "account_number_format_cz": "Broj računa mora biti u ispravnom formatu",
          "account_number_format_hr": "Broj računa mora biti u ispravnom formatu (10 znamenki)",
          "bank_login_id_required": "ID za prijavu u banku je potreban za ovu banku",
          "bank_login_id_format_hu": "ID za prijavu u banku mora biti u ispravnom formatu (8 znamenki)",
          "bank_login_id_format_hr": "OIB broj mora biti u ispravnom formatu (11 znamenki)",
          "bank_login_id_format_hr_otp": "mToken broj mora biti u ispravnom formatu (10 znamenki)",
          "window_open_text": "Ako se novi prozor nije pojavio, molimo kliknite ovdje za nastavak:",
          "continue": "Nastavi",
          "bank_not_on_the_list": "Moja banka nije na popisu",
          "refuse_consent": "Ne želim dati pristanak"
        }
      }
    }
  });

export default i18n;