import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { commonReducer } from '../reducer/CommonReducer';

const combinedReducer = combineReducers({
  commonReducer: commonReducer
});

const rootStore = configureStore({
    reducer: combinedReducer
});
export {
    rootStore
}