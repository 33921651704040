import { Box, Divider, Grid, Stack, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import { styled } from  '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useNavigation } from 'react-router';
import { checkConsentStatusRequest, commonActions, generateAuthorizationRequest, getClientDataRequest, rejectConsentRequest } from '../reducer/CommonReducer';
import { QueryUtils } from '../util/QueryUtils';
import FinishedWithErrorComponent from './steps/FinishedWithErrorComponent';
import FinishedWithSuccessComponent from './steps/FinishedWithSuccessComponent';
import Step1Component from './steps/Step1Component';
import Step2Component from './steps/Step2Component';
import Step3Component from './steps/Step3Component';
import Step4Component from './steps/Step4Component';
import {ReactComponent as BankDone} from '../assets/bank_done.svg';
import {ReactComponent as BankActive} from '../assets/bank_active.svg';
import {ReactComponent as BankDataNormal} from '../assets/bank_data.svg';
import {ReactComponent as BankDataActive} from '../assets/bank_data_active.svg';
import {ReactComponent as BankDataDone} from '../assets/bank_data_done.svg';
import {ReactComponent as ValidateNormal} from '../assets/validate.svg';
import {ReactComponent as ValidateActive} from '../assets/validate_active.svg';
import {ReactComponent as CheckBoxGreen} from '../assets/checkbox_circle_fill.svg';
import { ColorUtils } from '../util/ColorUtils';
import { config as apiConfig, selectedProfile } from '../config/config';

const maxAllowedStatusCallsNum = 30;
const statusCallPeriodInSeconds = 5;

let checkLastTime = false;
let consentStateIntervalCall = null;
let consentStateIntervalCallNum = 0;
let consentProcessWindow = null;

const prepareOpenWindow = () => {
    var baseUrl = apiConfig[selectedProfile].PUBLIC_API_BASE_URL;
    const w = 1024;
    const h = 768;
    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    consentProcessWindow = window.open(baseUrl+'/mitigate/public/loading', 'consentWindow', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

const handleOpeningWindow = (authUrl) => {
    if(consentProcessWindow) {
        if(authUrl) {
            consentProcessWindow.location.href = authUrl;
        }
        else {
            consentProcessWindow.close();
        }
    } 
}
export default() => {
    const dispatcher = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const bankList = useSelector(state => state.commonReducer.banks);

    const [hasData, setHasData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [activeBank, setActiveBank] = useState(null);
    const [activeUrl, setActiveUrl] = useState(null);
    
    
    const [consentFinished, setConsentFinished] = useState({
        finished: false,
        error: false
    });
    const clientToken = QueryUtils.getClientFromPath(location?.search);
    useEffect(() => {
        if(!clientToken) {
            navigate("/error");
        }
    },[clientToken]);
    const clientData = useSelector(state => state.commonReducer.user);
    const pendingConsent = useSelector(state => state.commonReducer.consent);
    useEffect(() => {
        if(!clientData && clientToken) {
            dispatcher(commonActions.setLoading(true));
            dispatcher(getClientDataRequest(clientToken)).unwrap().then(res => {
                dispatcher(commonActions.setLoading(false));
                if(res.success) {
                    setLoading(false);
                    setHasData(true);
                    if(res?.data?.clientData?.language && res?.data?.clientData?.language != i18n.language) {
                        i18n.changeLanguage(res?.data?.clientData?.language);
                    }
                }
                else {
                    navigate("/error");
                }
            }, e => {
                navigate("/error");
            });
        }
    });
    const steps = [t('menu_1'), t('menu_2'), t('menu_3')];
    const onNext = (bankLoginId, accountNumber) => {
        if(activeStep == 1) {
            prepareOpenWindow();
            startAuthorization(bankLoginId, accountNumber);
        }
        setActiveStep(activeStep + 1);
    }
    const onPrevious = () => {
        setActiveStep(activeStep - 1);
    }
    const onBankSelected = (id) => {
        setActiveBank(id);
        setActiveStep(activeStep + 1);
    }
    const onReject = (reason) => {
        const request = {token: clientToken, reason: reason};
        dispatcher(commonActions.setLoading(true));
        dispatcher(rejectConsentRequest(request)).unwrap().then(res => {
            dispatcher(commonActions.setLoading(false));
            if(res.success) {
                onFinishedSuccess();
            }
            else {
                onFinishedWithError();
            }
        }, e => {});
    }
    const startAuthorization = async (bankLoginId, accountNumber) => {
        if(!clientToken || !activeBank) return;
        let ipAddress = null;
        try {
            const clientInfo = await fetch("https://hutils.loxal.net/whois");
            const data = await clientInfo.json();
            ipAddress = data.ip;
        }
        catch(e) {}
        const request = {token: clientToken, bankId: activeBank, accountNumber: accountNumber?.trim(), bankLoginId: bankLoginId?.trim(), ipAddress: ipAddress};
        dispatcher(commonActions.setLoading(true));
        dispatcher(generateAuthorizationRequest(request)).unwrap().then(res => {
            dispatcher(commonActions.setLoading(false));
            if(res.success) {
                checkLastTime = false;
                setActiveUrl(res.data.authorizationUrl);
                handleOpeningWindow(res.data.authorizationUrl);
            }
            else {
                onFinishedWithError();
            }
        }, e => {});
    }
    useEffect(() => {
        if(pendingConsent && activeStep == 2 && !consentFinished.finished) {
            consentStateIntervalCall = setInterval(() => {
                consentStateIntervalCallNum++;
                const bankData = bankList?.data?.banks.filter(i => i.id == activeBank)?.pop();
                let shouldCheckForWindowClosure = (bankData?.swiftCode == "FIOBCZPP") ? false : consentProcessWindow && consentProcessWindow.closed;
                if(!checkLastTime && consentStateIntervalCallNum > maxAllowedStatusCallsNum || (shouldCheckForWindowClosure && !consentFinished.finished)) {
                    checkLastTime = true;
                }
                dispatcher(checkConsentStatusRequest({token: clientToken, consentId: pendingConsent?.data?.consentId})).unwrap().then(res => {
                    if(res && res.success && res.data) {
                        onFinishedSuccess();
                    }
                    else {
                        if(checkLastTime) {
                            onFinishedWithError();
                            clearInterval(consentStateIntervalCall);
                        }
                    }
                }, e => {
                    onFinishedWithError();
                });
            }, statusCallPeriodInSeconds * 1000);
            return () => {
                clearInterval(consentStateIntervalCall);
            };
        }
      });
      const onFinishedWithError = () => {
        setConsentFinished({finished: true, error: true});
        setActiveStep(3);
      }
      const onFinishedSuccess = () => {
        setConsentFinished({finished: true, error: false});
        setActiveStep(3);
      }
      const onRestart = () => {
        setConsentFinished({finished: false, error: false});
        setActiveStep(0);
      }

      const RootIconContainer = styled('div')(({ theme }) => ({
        width: '56px',
        height: '56px',
        backgroundColor: '#F4F5F7',
        borderRadius: '50%',
        [theme.breakpoints.down('md')]: {
            width: '39px',
            height: '39px',
        },
    }));
      
    const ActiveIconContainer = styled(RootIconContainer)(({ theme }) => ({
        backgroundColor: ColorUtils.getPrimaryBrandColor(theme),
    }));
    
    const CompletedIconContainer = styled(RootIconContainer)(({ theme }) => ({
        backgroundColor: ColorUtils.toRgbaWithOpacity(ColorUtils.getPrimaryBrandColor(theme), 0.2),
    }));

    const CustomStepIcon = (props) => {
        const { active, completed } = props;
        const stepIcons = {
            1: <BankActive />,
            2: <BankDataNormal />,
            3: <ValidateNormal />,
        };
        const stepIconsActive = {
            1: <BankActive />,
            2: <BankDataActive />,
            3: <ValidateActive />,
        };
        const stepIconsCompleted = {
            1: <BankDone />,
            2: <BankDataDone />,
            3: <ValidateActive />,
        };
        let selectedIcon = stepIcons[String(props.icon)];
        if(active) {
            selectedIcon = stepIconsActive[String(props.icon)];
            return(
                <ActiveIconContainer>
                    <div className="svgContainer">{selectedIcon}</div>
                </ActiveIconContainer>
            );
        }
        else if(completed) {
            selectedIcon = stepIconsCompleted[String(props.icon)];
            return(
                <CompletedIconContainer>
                    <div className="svgContainer">{selectedIcon}</div>
                </CompletedIconContainer>
            );
        }
        return (
            <RootIconContainer>
                <div className="svgContainer">{selectedIcon}</div>
            </RootIconContainer>
        );
    };

    const StepLabelCustomText = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    }));

    const StepLabelCustomTopText = styled(Typography)(({ theme }) => ({
        fontSize: '16px',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px'
        },
    }));

    const StepLabelCustomBottomText = styled(Typography)(({ theme }) => ({
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px'
        },
    }));

    const displayMain = hasData ? 'block' : 'none';

    return(
        <Box sx={{marginTop: '22px', display: displayMain}}>
            <Grid container spacing={0} direction={{ xs: 'column', md: 'row' }}>
                <Grid item>
                    <Typography variant="h3" color="primary" sx={{marginRight: '16px', color: ColorUtils.getPrimaryBrandColor(theme)}}>{t('main_subtitle')}</Typography>
                </Grid>
                <Grid item sx={{marginTop: '12px'}}>
                    <Typography variant="body" className='subHeader'>{t('main_subtitle2')}</Typography>
                </Grid>
            </Grid>
            <Box component="img" src={theme.branding.bannerUrl} alt={"Logo"} sx={{borderRadius: '8px', objectFit: 'cover', margin:'auto', height: "180px", width: "100%", marginTop: '24px'}} />
            <Box className="mainStepperContainer" sx={{marginTop: '24px'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel StepIconComponent={CustomStepIcon}>
                                    <Stack>
                                        <StepLabelCustomText className="stepTopText">
                                            <StepLabelCustomTopText variant="body" className="regular16">{t('step')} {index+1}/3</StepLabelCustomTopText>
                                            <CheckBoxGreen style={{display:'inline-block', visibility: 'hidden'}} />
                                        </StepLabelCustomText>
                                        <StepLabelCustomText className="stepBottomText">
                                            <StepLabelCustomBottomText variant="body" className="bold18">{label}</StepLabelCustomBottomText>
                                        </StepLabelCustomText>
                                    </Stack>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Divider sx={{marginLeft: '8px', marginRight: '8px', marginTop: '16px'}} flexItem />
                <Box>
                    {error &&
                        <p>There was an error during loading.</p>
                    }
                    {/*!error && activeStep == 0 && !loading && 
                        <Step1Component clientData={clientData?.data} loading={loading} onNext={onNext} />*/
                    }
                    {/*!error && activeStep == 0 && loading && 
                        <p>Loading client data</p>*/
                    }
                    {!error && activeStep == 0 &&
                        <Step2Component clientToken={clientToken} loading={loading} setLoading={setLoading} onBankSelected={onBankSelected} onReject={onReject} />
                    }
                    {!error && activeStep == 1 &&
                        <Step3Component clientToken={clientToken} selectedBank={activeBank} loading={loading} setLoading={setLoading} onPrevious={onPrevious} onNext={onNext} />
                    }
                    {!error && !consentFinished.error && !consentFinished.finished && activeStep == 2 &&
                        <Step4Component clientToken={clientToken} selectedBank={activeBank} loading={loading} setLoading={setLoading} onPrevious={onPrevious} onNext={onNext} activeUrl={activeUrl} />
                    }
                    {consentFinished.error && consentFinished.finished && 
                        <FinishedWithErrorComponent clientToken={clientToken} selectedBank={activeBank} loading={loading} setLoading={setLoading} onRestart={onRestart} />
                    }
                    {!consentFinished.error && consentFinished.finished && 
                        <FinishedWithSuccessComponent clientToken={clientToken} selectedBank={activeBank} loading={loading} setLoading={setLoading} />
                    }
                </Box>
            </Box>
        </Box>
    );
}